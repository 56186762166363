import { PopupDatasetLockedFeature } from 'devlink'
import { PopupDatasetTutorial } from 'devlink/Dataset/PopupDatasetTutorial'
import React, { useCallback, useContext, useEffect } from 'react'
import { DatasetContext } from '../../pages/dataset/dataset-page.model'

export function PopupDatasetBestPracticeContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTrigger,
    } = useContext(DatasetContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false)
    }, [setIsModalBestPracticeOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <PopupDatasetTutorial
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTrigger?.(true)
                },
            }}
        />
    )
}

export function PopupLocked() {
    const { isModalLockedOpen, setIsModalLockedOpen } =
        useContext(DatasetContext)

    const handleClosePopup = useCallback(() => {
        setIsModalLockedOpen?.(false)
    }, [setIsModalLockedOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <PopupDatasetLockedFeature
            visibility={isModalLockedOpen}
            rpButtonConfirm={{ onClick: handleClosePopup }}
            rpClosePopup={{ onClick: handleClosePopup }}
        />
    )
}
