import { PopupRollTutorial, PopupRollExport, PopupRollRestart } from 'devlink'
import { RollContext } from 'pages/roll/roll-page.model'
import { useContext, useEffect, useCallback } from 'react'

export function PopupRollRestartContainer() {
    const { isModalRestartOpen, setIsModalRestartOpen, rollStore } =
        useContext(RollContext)

        const handleClosePopup = useCallback(() => {
            setIsModalRestartOpen?.(false);
        }, [setIsModalRestartOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]); 

    return (
        <PopupRollRestart
            visibility={isModalRestartOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                    rollStore.getState().reset()
                    window.location.reload()
                },
            }}
        />
    )
}

export function PopupRollExportContainer() {
    const { isModalExportOpen, setIsModalExportOpen } =
        useContext(RollContext)

        const handleClosePopup = useCallback(() => {
            setIsModalExportOpen?.(false);
        }, [setIsModalExportOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]); 

    return (
        <PopupRollExport
            visibility={isModalExportOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalExportOpen?.(false)
                },
            }}
            // rpButtonExport={{
            //     className: 'button is-disabled',
            // }}
        />
    )
}

export function PopupRollTutorialContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTrigger,
    } = useContext(RollContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false)
    }, [setIsModalBestPracticeOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <PopupRollTutorial
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTrigger?.(true)
                },
            }}
        />
    )
}
