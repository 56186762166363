import { PopupArielTutorial, PopupVoiceExport, PopupVoiceRestart } from 'devlink'
import { VoiceContext } from 'pages/voice/voice-page.lib'
import React, { useContext, useEffect, useCallback } from 'react';

export function PopupVoiceRestartContainer() {
    const { isModalRestartOpen, setIsModalRestartOpen, voiceStore } =
        useContext(VoiceContext)

        const handleClosePopup = useCallback(() => {
            setIsModalRestartOpen?.(false);
        }, [setIsModalRestartOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
    
            document.addEventListener('keydown', handleEscKey);
    
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]);

    return (
        <PopupVoiceRestart
            visibility={isModalRestartOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                    voiceStore.getState().reset()
                    window.location.reload()
                },
            }}
        />
    )
}

export function PopupArielTutorialContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTrigger,
    } = useContext(VoiceContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false)
    }, [setIsModalBestPracticeOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <PopupArielTutorial
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTrigger?.(true)
                },
            }}
        />
    )
}


export function PopupVoiceExportContainer() {
    const { isModalExportOpen, setIsModalExportOpen } = useContext(VoiceContext)

    const handleClosePopup = useCallback(() => {
        setIsModalExportOpen?.(false);
    }, [setIsModalExportOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupVoiceExport
            visibility={isModalExportOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalExportOpen?.(false)
                },
            }}
        />
    )
}
