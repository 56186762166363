import React, { useContext, useLayoutEffect, useState } from 'react';
import { DemosAnimation, VideoAndEvilLordDemo } from 'devlink';
import { characterStore } from 'entities/character/character.model';

export function SectionAnimationContainer() {
  return (
    <>
      <DemosAnimationContainer />
      <VideoAndEvilLordDemoContainer />
    </>
  );
}

export function DemosAnimationContainer() {
  const [accessRights, setAccessRights] = useState({
    can_download_ariel: 0,
    can_download_diagen: 0,
    can_download_gepetto: 0,
  });

  useLayoutEffect(() => {
    const setAccessRightsFromStore = (state: any) => {
      setAccessRights({
        can_download_ariel: state.currentTeam?.can_download_ariel || 0,
        can_download_diagen: state.currentTeam?.can_download_diagen || 0,
        can_download_gepetto: state.currentTeam?.can_download_gepetto || 0,
      });
    };

    const currentState = characterStore.getState();
    setAccessRightsFromStore(currentState);

    const unsubscribe = characterStore.subscribe((state) => {
      if (state.currentTeam) {
        setAccessRightsFromStore(state);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <DemosAnimation
        rpButtonUnrealGeppettoDemoSceneUE2={{
          label: 'Download Geppetto 5.2 Unreal Engine',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/DemoSceneGeppetto+5.2.zip', '_blank');
          },
        }}
        rpButtonUnrealGeppettoDemoSceneUE3={{
          label: 'Download Geppetto 5.3 Unreal Engine',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/DemoSceneGeppetto+5.3.zip', '_blank');
          },
        }}
        rpButtonUnrealGeppettoDemoSceneUE4={{
          label: 'Download Geppetto 5.4 Unreal Engine',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/DemoSceneGeppetto+5.4.zip', '_blank');
          }
        }}
        rpButtonUnrealGeppettoDemoSceneUE5={{
          label: 'Download Geppetto 5.5 Unreal Engine',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/DemoSceneGeppetto+5.5.zip.zip', '_blank');
          }
        }}
        rpButtonUnityGeppettoDemoScene={{
          label: 'Download Geppetto 2021 2021.3 Unity',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/Geppetto_Demoscene_Unity.zip', '_blank');
          },
        }}
        rpButtonVideoTutorialGeppettoUnreal={{
          className: 'button is-primary',
          label: 'Watch Geppetto Video Tutorial',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://youtu.be/BHoKaU5GLHg', '_blank');
          },
        }}
        rpButtonVideoTutorialGeppettoUnity={{
          className: 'button is-primary',
          label: 'Watch Geppetto Video Tutorial',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://youtu.be/DeKp6ljbzZ8', '_blank');
          },
        }}
        rpUserAccessRights={accessRights}
      />
    </>
  );
}

export function VideoAndEvilLordDemoContainer() {
  const [accessRights, setAccessRights] = useState({
    can_download_ariel: 0,
    can_download_diagen: 0,
    can_download_gepetto: 0,
  });

  useLayoutEffect(() => {
    const setAccessRightsFromStore = (state: any) => {
      setAccessRights({
        can_download_ariel: state.currentTeam?.can_download_ariel || 0,
        can_download_diagen: state.currentTeam?.can_download_diagen || 0,
        can_download_gepetto: state.currentTeam?.can_download_gepetto || 0,
      });
    };

    const currentState = characterStore.getState();
    setAccessRightsFromStore(currentState);

    const unsubscribe = characterStore.subscribe((state) => {
      if (state.currentTeam) {
        setAccessRightsFromStore(state);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <VideoAndEvilLordDemo
        rpButtonEvilLordDownload={{
          label: 'Download Unreal Evil Lord Demo Scene',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/EvilLord_v0_9.zip', '_blank');
          },
        }}
        rpUserAccessRights={accessRights}
      />
    </>
  );
}