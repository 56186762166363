import { HeaderLayout } from 'devlink'
import { useLayoutEffect, useState } from 'react'
import { PageHeaderLogs, LogsAnalysis, PopupLogsTutorial  } from 'widgets/logsanalysis'
import { LogsProvider } from './logsanalysis-page.model'

export function LogsAnalysisPage() {
    return (
        <LogsProvider>
            <HeaderLayout
                slotHeader={<PageHeaderLogs />}
                slotHeaderLayout={
                <LogsAnalysis/>
                }

            />
            <PopupLogsTutorial/>
        </LogsProvider>
    )
}
