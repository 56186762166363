import { dialogModel } from 'entities/dialog'
import { StoreApi } from 'zustand'

export function 
regenerateOneLine(
    regenerateDialogLine: any,
    dialogStore: StoreApi<dialogModel.State>,
    params: {
        lineIndex: number
        toxicity: number
        llama_generation_params: {
            max_tokens: number
            temperature: number
            stream: boolean
            // stop: string[]
            top_p: number
            top_k: number
            frequency_penalty: number
            presence_penalty: number
        }
        language: string
        instruction: string
    }
) {
    const state = dialogStore.getState()
    const previousLine =
        params.lineIndex > 0 ? state.dialogLines.at(params.lineIndex - 1) : null

    const npc =
        params.lineIndex % 2 === 0
            ? state.characterStates[0].character
            : state.characterStates[1].character
    const player =
        params.lineIndex % 2 === 0
            ? state.characterStates[1].character
            : state.characterStates[0].character

    const background =
    params.lineIndex % 2 === 0
            ? state.characterStates[0].background
            : state.characterStates[1].background

    if (state.dialogLines.length < params.lineIndex) {
        return
    }

    // history
    let history = null
    if (params.lineIndex >= 3) {
        history = state.dialogLines.slice(params.lineIndex - 3, params.lineIndex).map((line) => line.response)
    } else if (params.lineIndex === 2) {
        history = state.dialogLines.slice(0, 2).map((line) => line.response)
    } else if (params.lineIndex === 1) {
        history = state.dialogLines.slice(0, 1).map((line) => line.response)
    }

    const randomVariance =  Math.random() * 0.3;
    const randomVarianceTOKEN = Math.random() * 20 - 10; 
    const randomVarianceToxicity =  Math.random() * 0.15; 

    const newTemperature = params.llama_generation_params.temperature + randomVariance; 
    let newMaxToken =  Math.round(params.llama_generation_params.max_tokens + randomVarianceTOKEN);
    let newToxicity =  Math.round((params.toxicity + randomVarianceToxicity) * 100) / 100;
    

    const newLineParams = {
        toxicity: Math.max(0, Math.min(1, newToxicity)), 
        question: previousLine?.response || '',
        instruction: params.instruction || '',
        llama_generation_params: {
            max_tokens: Math.max(10, Math.min(200, newMaxToken)),
            temperature: Math.max(0, Math.min(2, newTemperature)),
            stream: true,
            // stop: params.llama_generation_params.stop,
            top_p: params.llama_generation_params.top_p,
            top_k: params.llama_generation_params.top_k,
            frequency_penalty: params.llama_generation_params.frequency_penalty,
            presence_penalty: params.llama_generation_params.presence_penalty,
        },
        npc_name: npc?.name || '',
        player_name: player?.name || '',
        core_description: background || '',
        language: params.language,
        ...(history && { history }),
    } 

    // Update the doalog line to dialogStore
    dialogStore.setState((state) => {
        const dialogLines = state.dialogLines
        dialogLines[params.lineIndex] = {
            ...dialogLines[params.lineIndex],
            ...newLineParams,
            response: '',
        }
        return {
            ...state,
            dialogLines,
        }
    })

    regenerateDialogLine({
        dialogLine: newLineParams,
        onChunkReceived: (chunk: string) => {
            
            // Update the selected line
            dialogStore.setState((state) => ({
                ...state,
                dialogLines: state.dialogLines.map((line, index) =>
                    index === params.lineIndex
                        ? { ...line, response: line.response + chunk }
                        : line
                ),
            }))
        }
    })

}
