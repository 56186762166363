import { useQuery } from '@tanstack/react-query'
import { HeaderLayout } from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import { emotionQueries } from 'entities/emotion'
import { useEffect, useState } from 'react'
import { PageHeaderDataset, PopupDatasetTutorial, PopupLocked, SectionDataset } from 'widgets/dataset'
import { DatasetProvider } from './dataset-page.model'

interface SectionDatasetProps {
    emotion: string
}

export function DatasetPage() {
    const { data: emotions, isPending: isEmotionLoading } = useQuery(
        emotionQueries.emotionsService.queryOptions()
    )
    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
        valid_until: '',
        valid_from: '',
    }
    const initialCharacter = {
        id: 0,
        name: "New Character",
        image: "",
        background: "",
        ariel_name: "",
        emotion: [],
        language: [],
        trigger_exists: false,
        dataset_exists: false,
        preset: 0,
    }
    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)
    const [characters, setCharacters] = useState<Npc[]>([initialCharacter, ...characterStore.getState().npcs|| []])
    const [audios, setAudios] = useState<any[]>([])
    const [images, setImages] = useState<any[]>([])
    
    useEffect(() => {
        const setComponentStateFromStore = (state: any) => {
            setCharacters([initialCharacter, ...(Array.isArray(state.npcs) ? state.npcs : [])]);
            setSelectedTeam(state.currentTeam || initialTeam);
            setImages(state.images || []);
            setAudios(state.audios || []);
        };
    
        // Manually set the initial state from the store
        const initialState = characterStore.getState();
        setComponentStateFromStore(initialState);
    
        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.npcs && state.currentTeam && state.images && state.audios) {
                setComponentStateFromStore(state);
            }
        });
    
        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };
        
    }, [])

    return (
        <>
            <DatasetProvider>
                <HeaderLayout
                    slotHeader={
                        <PageHeaderDataset/>
                    }
                    slotHeaderLayout={<SectionDataset emotions={emotions} selectedTeam={selectedTeam} characters={characters} images={images} audios={audios}/>}
                />
                <PopupDatasetTutorial />
                <PopupLocked />
            </DatasetProvider>
        </>
    )
}
