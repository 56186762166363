import { HeaderLayout } from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { sessionStore } from 'entities/session/session.model'
import { useEffect, useState } from 'react'
import {
    AdminContainer,
    PageHeaderAdmin,
    PopupAdminAccessRights,
    PopupAdminAddMember,
    PopupAdminCreate,
    PopupAdminDelete,
    PopupAdminModify,
    PopupAdminTutorial,
    PopupAdminWarning,
} from 'widgets/admin'
import { AdminProvider } from './admin-page.model'

export function AdminPage() {
    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
        valid_until: '',
        valid_from: '',
    }
    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)
    const [selectedKeys, setSelectedKeys] = useState<any>(sessionStore.getState().keys || {})

    useEffect(() => {
        // Character store
        const setComponentStateFromCharacterStore = (state: any) => {
            setSelectedTeam(state.currentTeam || initialTeam);
        };

    
        // Manually set the initial state from the store
        const initialCharacterState = characterStore.getState();
        setComponentStateFromCharacterStore(initialCharacterState);
    
        // Subscribe to future store changes
        const unsubscribeCharacterStore = characterStore.subscribe((state) => {
            if (state.currentTeam) {
                setComponentStateFromCharacterStore(state);
            }
        });
    
        // Session store
        const setComponentStateFromSessionStore = (state: any) => {
            setSelectedKeys(state.keys || {});
        };

        // Manually set the initial state from the store
        const initialSessionState = sessionStore.getState();
        setComponentStateFromSessionStore(initialSessionState);
    
        // Subscribe to future store changes
        const unsubscribeSessionStore = sessionStore.subscribe((state) => {
            if (state.keys) {
                setComponentStateFromSessionStore(state);
            }
        });
    

        // Cleanup function to unsubscribe
        return () => {
            unsubscribeCharacterStore();
            unsubscribeSessionStore();
        };
    }, [])



    return (
        <AdminProvider>
            <HeaderLayout
                slotHeader={<PageHeaderAdmin />}
                slotHeaderLayout={
                    <>
                        <AdminContainer selectedTeam={selectedTeam} selectedKeys={selectedKeys}/>
                    </>
                }
            />
            <PopupAdminDelete />
            <PopupAdminCreate />
            <PopupAdminModify selectedTeam={selectedTeam.name}/>
            <PopupAdminWarning /> 
            <PopupAdminAccessRights selectedTeam={selectedTeam.name}/>
            <PopupAdminAddMember />
            <PopupAdminTutorial/>
        </AdminProvider>
    )
}
