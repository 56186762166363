import { SectionIntegration, IntegrationUnrealModules, IntegrationUnityModules, Discord } from 'devlink';
import { characterStore } from 'entities/character/character.model';
import { useLayoutEffect, useState, useContext } from 'react';

export function SectionIntegrationContainer() {

    return (
        <>
            <SectionIntegration />
            <IntegrationUnrealModulesContainer />
            <IntegrationUnityModulesContainer />
            <DiscordContainer />
        </>
    )
}


export function IntegrationUnrealModulesContainer() {
    const [accessRights, setAccessRights] = useState({
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0
    })
    const downloadFile = (path: string, filename: string) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useLayoutEffect(() => {
        const setAccessRightsFromStore = (state: any) => {
            setAccessRights(
                {
                    can_download_ariel: state.currentTeam?.can_download_ariel || 0,
                    can_download_diagen: state.currentTeam?.can_download_diagen || 0,
                    can_download_gepetto: state.currentTeam?.can_download_gepetto || 0
                }
            )
        };

        // Manually set the initial state from the store
        const currentState = characterStore.getState();
        setAccessRightsFromStore(currentState);

        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.currentTeam) {
                setAccessRightsFromStore(state);
            }
        });

        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>
            <IntegrationUnrealModules
                rpButtonUnrealArielDownload52={{
                    label: 'Download Ariel 5.2 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/X%26Immersion-Ariel-PlugIn-5.2.zip', 'X&Immersion-Ariel-PlugIn-5.2.zip')
                    }
                }}
                rpButtonUnrealArielDownload53={{
                    label: 'Download Ariel 5.3 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('/https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/X%26Immersion-Ariel-PlugIn-5.3.zip', 'X&Immersion-Ariel-PlugIn-5.3.zip')
                    }
                }}
                rpButtonUnrealArielDownload54={{
                    label: 'Download Ariel 5.4 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('/https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/X%26Immersion-Ariel-PlugIn-5.4.zip', 'X&Immersion-Ariel-PlugIn-5.4.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload52={{
                    label: 'Download Geppetto 5.2 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUE5.2%5D+Geppetto+plugin+v1.1.0.zip', 'X&Immersion-Geppetto-PlugIn-5.2.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload53={{
                    label: 'Download Geppetto 5.3 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUE5.3%5D+Geppetto+plugin+v1.1.0.zip', 'X&Immersion-Geppetto-PlugIn-5.3.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload54={{
                    label: 'Download Geppetto 5.4 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUE5.4%5D+Geppetto+plugin+v1.1.0.zip', 'X&Immersion-Geppetto-PlugIn-5.4.zip')
                    }
                }}
                rpButtonUnrealGeppettoDownload55={{
                    label: 'Download Geppetto 5.5 Unreal Engine',
                    onClick: (e: Event) => {
                        e.preventDefault()

                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUE5.5%5D+Geppetto+plugin+v1.1.0.zip', 'X&Immersion-Geppetto-PlugIn-5.5.zip')
                    }
                }}
                rpUserAccessRights={accessRights}
            />
        </>
    )
}

export function IntegrationUnityModulesContainer() {
    const [accessRights, setAccessRights] = useState({
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0
    })
    const downloadFile = (path: string, filename: string) => {
        const link = document.createElement('a');
        link.href = path;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useLayoutEffect(() => {
        const setAccessRightsFromStore = (state: any) => {
            setAccessRights(
                {
                    can_download_ariel: state.currentTeam?.can_download_ariel || 0,
                    can_download_diagen: state.currentTeam?.can_download_diagen || 0,
                    can_download_gepetto: state.currentTeam?.can_download_gepetto || 0
                }
            )
        };

        // Manually set the initial state from the store
        const currentState = characterStore.getState();
        setAccessRightsFromStore(currentState);

        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.currentTeam) {
                // console.log("Team", state.currentTeam)
                setAccessRightsFromStore(state);
            }
        });

        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>
            <IntegrationUnityModules
                rpUserAccessRights={accessRights}
                rpButtonUnityArielVideo={{
                    className: 'button is-icon is-secondary',
                    label: 'Video',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/ARIEL+-+UNITY+TUTORIAL.mp4', '_blank')
                    }
                }}
                rpButtonUnityGeppettoVideo={{
                    className: 'button is-icon is-secondary',
                    label: 'Video',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        window.open('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/GEPPETTO+UNITY+TUTORIAL+(1).mp4', '_blank')
                    }
                }}
                rpButtonUnityArielDocumentation={{
                    className: 'button is-icon',
                    label: 'Documentation',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/documentation/Unity_Ariel_Doc.pdf', 'X&Immersion-Ariel-Unity-Documentation.pdf')
                    }
                }}
                rpButtonUnityGeppettoDocumentation={{
                    className: 'button is-icon',
                    label: 'Documentation',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('/documentation/Unity_Geppetto_Doc.pdf', 'X&Immersion-Geppetto-Unity-Documentation.pdf')
                    }
                }
                }
                rpButtonUnityArielDownload={{
                    label: 'Download Ariel Unity',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUnity%5D+-+X%26Immersion+Ariel+2020%2B.zip', 'X&Immersion-Ariel-PlugIn-2020+.zip')
                    }
                }}
                rpButtonUnityGepettoDownload={{
                    label: 'Download Geppetto Unity',
                    onClick: (e: Event) => {
                        e.preventDefault()
                        downloadFile('https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/%5BUnity%5D+-+X%26Immersion+Geppetto+2021.3.zip', 'X&Immersion-Geppetto-Unity-2021.3+.zip')
                    }
                }}

            />
        </>
    )
}
export function DiscordContainer() {

    return (
        <>
            <Discord
            />
        </>
    )
}


