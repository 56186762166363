import { HeaderLayout } from 'devlink'
import { useLayoutEffect, useState } from 'react'
import { PageHeaderCorporateHelper, CorporateHelper, PopupCorporateHelperTutorial } from 'widgets/corporatehelper'
import { CorporateHelperProvider } from './corporate-helper-page.model'

export function CorporateHelperPage() {
    const [language, setLanguage] = useState('en')

    return (
        <CorporateHelperProvider>
            <HeaderLayout
                slotHeader={<PageHeaderCorporateHelper />}
                slotHeaderLayout={
                    <CorporateHelper setLanguage={setLanguage}  language={language}
                    />
                }

            />
            <PopupCorporateHelperTutorial />
        </CorporateHelperProvider>
    )
}
