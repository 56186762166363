import { HeaderLayout } from 'devlink'
import { characterStore } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import { useLayoutEffect, useState } from 'react'
import {
    PageHeaderVoice,
    PopupVoiceExport,
    PopupVoiceRestart,
    PopupArielTutorial,
    VoiceGeneration,
} from 'widgets/voice'
import { VoiceProvider } from './voice-page.lib'

export function VoicePage() {
    const [characters, setCharacters] = useState<Npc[]>(characterStore.getState().npcs || [])

    // sort characters by name
    if (characters) {
        characters.sort((a, b) => a.name.localeCompare(b.name))
    }

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (state.npcs) {
                setCharacters(state.npcs)
            }
        })
    }, [])

    return (
        <>
            <VoiceProvider>
                <HeaderLayout
                    slotHeader={<PageHeaderVoice />}
                    slotHeaderLayout={
                        <VoiceGeneration
                            characters={characters}
                        />
                    }
                />
                <PopupVoiceRestart />
                <PopupVoiceExport />
                <PopupArielTutorial />
            </VoiceProvider>
        </>
    )
}
